<template>
  <div v-if="shouldDisplay" :id="uniqueId" class="column" />
</template>

<script>
import postscribe from 'postscribe';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      uniqueId: `${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
    };
  },
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.search_banner_ads,
    }),
    shouldDisplay() {
      return !!this.ads?.length;
    },
  },
  mounted() {
    setTimeout(() => {
      if ((this.ads?.length ?? 0) > 0) {
        postscribe(`#${this.uniqueId}`, this.ads[Math.floor(Math.random() * this.ads.length)]);
      }
    }, 1000);
  },
};
</script>
